.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.macchinari-image {
  border-radius: 4px;
  margin: auto;
  height: 280px;
  width: 373px;
}
.personale-image {
  border-radius: 4px;
  margin: auto;
  height: 180px;
}
@media (max-width: 768px) {
  div > .macchinari-image {
    height: 280px !important;
    width: 373px !important;
  }
}
@media (max-width: 1000px) {
  .macchinari-image {
    height: 150px !important;
    width: 200px !important;
  }
}
@media (max-width: 1200px) {
  .macchinari-image {
    height: 225px;
    width: 300px;
  }
}

input[type="file"] {
  display: block;
  margin: 10px 2px;
}

.status-circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}
.status-done {
  background-color: green;
}
.status-queued {
  background-color: grey;
}
.status-running {
  background-color: orange;
}

.status-error {
  background-color: red;
}
