/* @import "../node_modules/bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/utilities"; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #2d338a;
}

.download-table-xls-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;

  color: #fff;
  background-color: #198754;
  border-color: #198754;

  display: inline-block;
  font-weight: 400;
  line-height: 1.5;

  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-bg-color {
  background-color: #009a3d;
}

@media print {
  .hide-print {
    display: none;
  }
  .print-container,
  .print-container * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    height: 60%;
  }
}

//VARIABLES
$fond-blue: #2d338a;
$fond-green: #009a3d;
$box-shadow-lg: 8px 8px 5px rgba(#ffffff, 0.5);
$box-shadow-black: 8px 8px 5px rgba(#000000, 0.5);

.text-blue {
  color: $fond-blue;
}
.bg-blue {
  background-color: $fond-blue;
}
.border-classic {
  border: 1px solid rgba(0, 0, 0, 0.225);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.225);
}
.bg-green {
  background-color: $fond-green !important;
}
.bg-red {
  background-color: red !important;
}
.green {
  color: $fond-green !important;
}
.input-green {
  /*  background-color: $fond-green !important; */
  border-color: $fond-green !important;
  /* accent-color: white !important; */
}
.btn-green {
  background-color: $fond-green !important;
  border-color: $fond-green !important;
}
.shadow-try {
  box-shadow: $box-shadow-lg;
}
.shadow-black {
  box-shadow: $box-shadow-black;
}
.bg-gradient {
  background-color: red;
  background-image: linear-gradient(to right, red, orange);
}

.nav-link {
  background-color: $fond-blue;
  border-radius: 0.25rem !important;
  color: #fff !important;
  font-size: 1.25rem !important;
  .dropdown-item {
    width: "100px" !important;
  }
}

$min-width: 210px;

@mixin transform($value) {
  -webkit-transform: ($value);
  -moz-transform: ($value);
  -ms-transform: ($value);
  transform: ($value);
}
@mixin transition($value) {
  -webkit-transition: ($value);
  -moz-transition: ($value);
  -ms-transition: ($value);
  transition: ($value);
}

#main {
  position: relative;
  list-style: none;
  font-weight: 400;
  font-size: 0;
  text-transform: uppercase;
  display: inline-block;
  padding: 0;
  margin: auto;
  text-align: center;

  li {
    font-size: 1rem;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    z-index: 5;
    min-width: $min-width;
  }
}
@media only screen and (max-width: 1200px) {
  #main {
    list-style: none;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    margin: 0;
    padding: 0px 0px;
    --bs-gutter-x: 0rem !important;
    li {
      padding: 0px 0px;
      display: flex;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      .row {
        --bs-gutter-x: 0px;
      }
      ul {
        li {
          padding: 0px 0px;
          display: flex;
          justify-content: end;
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}
.drop {
  overflow: hidden;
  list-style: none;
  position: absolute;
  padding: 0;
  width: 100%;
  left: 0;
  top: 65px;
  div {
    @include transform(translate(0, -100%));
    @include transition(all 0.5s 0.1s);
    position: relative;
    li {
      display: block;
      padding: 0;
      width: 100%;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.225);
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.225);
      z-index: 1050;
      @media only screen and (min-width: 1201px) {
        &:hover {
          background-color: #009a3d !important;
          color: #fff;
        }
      }

      @media only screen and (max-width: 1200px) {
        &:focus {
          background-color: #009a3d !important;
          color: #fff;
        }
      }
    }
    ul {
      position: relative;
      padding: 0;
      div {
        li {
          font-size: 0.9rem !important;
          display: block;
          padding: 5px 10px !important;
          width: 100%;
          background: white;
          border: 1px solid rgba(0, 0, 0, 0.225);
          border-width: 1px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.225);
          z-index: 1150;
        }
      }
    }
  }
}

.nav-li-mobile{
  justify-content: center !important;
  font-size: 18px;
}

#marker {
  height: 6px;
  background: $fond-green !important;
  position: absolute;
  bottom: 0;
  width: $min-width;
  z-index: 20;
  @include transition(all 0.35s);
}

@for $i from 1 through 4 {
  #main li:nth-child(#{$i}) {
    &:hover ul div {
      @include transform(translate(0, 0));
    }
    &:hover ~ #marker {
      @include transform(translate(#{(-1 + $i) * $min-width}, 0));
    }
  }
}

#mainProfile {
  position: relative;
  list-style: none;
  font-weight: 400;
  font-size: 0;
  text-transform: uppercase;
  display: inline-block;
  padding: 0;
  margin: auto 0;
  text-align: left;
  li {
    font-size: 1rem;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    z-index: 5;
    min-width: 150px;
  }
}
.dropProfile {
  overflow: hidden;
  list-style: none;
  position: absolute;
  padding: 0;
  width: 100%;
  left: 0;
  top: 80px;
  div {
    @include transform(translate(0, -100%));
    @include transition(all 0.5s 0.1s);
    position: relative;
    li {
      display: block;
      padding: 0;
      width: 100%;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.225);
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.225);
      &:hover {
        background-color: #009a3d !important;
        color: #fff;
      }
    }
  }
}

@for $i from 1 through 4 {
  #mainProfile li:nth-child(#{$i}) {
    &:hover ul div {
      @include transform(translate(0, 0));
    }
  }
}
